package dev.valvassori.minesweeper.model

private const val BOMB_EMOJI = "💣"

data class NodeData(
    val value: String,
    val mark: MarkType = MarkType.NONE,
    val isOpen: Boolean = false,
) {
    val isMine: Boolean = value == BOMB_EMOJI
    val isEmpty: Boolean = value.isEmpty()

    fun open() = if (isOpen) this else copy(isOpen = true)

    fun toggleMark() = copy(
        mark = MarkType.values().let { allMarks ->
            allMarks[(mark.ordinal + 1) % allMarks.size]
        }
    )

    fun inc() = if (isMine) this else copy(
        value = when {
            value.isEmpty() -> "1"
            value.toIntOrNull() != null -> {
                value.toInt().inc()
                    .coerceAtMost(8)
                    .toString()
            }
            else -> value
        }
    )

    companion object {
        fun createMine() = NodeData(value = BOMB_EMOJI)
        fun createEmpty() = NodeData(value = "")
    }
}
