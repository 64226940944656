package dev.valvassori.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import dev.valvassori.minesweeper.MinesweeperSharedViewModel
import dev.valvassori.nineeightcss.StatusBar
import dev.valvassori.nineeightcss.StatusBarField
import org.jetbrains.compose.web.dom.Text

@Composable
fun GameStatus(viewModel: MinesweeperSharedViewModel) {
    val highScore by viewModel.highScore.collectAsState()

    StatusBar {
        StatusBarField { Text("Press F1 to open menu") }
        StatusBarField {
            Text(
                if (highScore != null) {
                    "High Score: ${highScore?.score} (${highScore?.username})"
                } else {
                    "High Score: None"
                }
            )
        }
    }
}
