package dev.valvassori.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import dev.valvassori.minesweeper.MinesweeperSharedViewModel
import dev.valvassori.nineeightcss.Container
import dev.valvassori.theme.MineSweeperCSS
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Tr

@Composable
fun GameBoard(viewModel: MinesweeperSharedViewModel) {
    val board by viewModel.board.collectAsState()
    val gameState by viewModel.gameState.collectAsState()

    Container(
        windowBody = false,
        attrs = {
            classes(MineSweeperCSS.lowerContainer, MineSweeperCSS.gameBoard)
        }
    ) {
        Table {
            board.forEachIndexed { rowIdx, row ->
                Tr {
                    row.forEachIndexed { columnIdx, data ->
                        FieldItem(
                            position = rowIdx to columnIdx,
                            data = data,
                            gameState = gameState,
                            open = { viewModel.open(it) }
                        ) { viewModel.toggleMark(it) }
                    }
                }
            }
        }
    }
}
