package dev.valvassori.minesweeper

import dev.valvassori.minesweeper.datasource.MineSweeperAPI
import dev.valvassori.minesweeper.datasource.MineSweeperStorage
import dev.valvassori.minesweeper.datasource.MineSweeperStorageDependencies
import dev.valvassori.minesweeper.domain.GameBoard
import dev.valvassori.minesweeper.ext.inc
import dev.valvassori.minesweeper.model.*
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.launch

class MinesweeperSharedViewModel(
    private val api: MineSweeperAPI,
    private val storage: MineSweeperStorage,
) {
    constructor(
        env: Env,
        storageArgs: MineSweeperStorageDependencies
    ) : this(MineSweeperAPI(env), MineSweeperStorage(storageArgs))

    private var isRunning: Boolean = false
    private var game: GameBoard = GameBoard(storage.difficulty)
        set(value) {
            field = value
            isRunning = false
        }

    private val coroutineScope = MainScope()

    private val _gameState = MutableStateFlow(game.gameState)
    private val _boardState = MutableStateFlow(game.matrix)
    private val _playsCount = MutableStateFlow(0)
    private val _elapsedTime = MutableStateFlow(0)
    private val _highScore = MutableStateFlow<Score?>(null)

    private val score: Int get() {
        val difficulty = game.difficulty

        val time = _elapsedTime.value
        val plays = _playsCount.value

        val initialScore = difficulty.initialScore
        val timeScore = difficulty.scoreReductionMultiplier * (time - 1)
        val actionScore = difficulty.scoreReductionMultiplier * (plays - 1)

        return (initialScore - (timeScore + actionScore))
            .coerceAtLeast(0)
            .coerceAtMost(initialScore)
    }

    val board: StateFlow<Array<Array<NodeData>>> = _boardState.asStateFlow()
    val gameState: StateFlow<GameState> = _gameState.asStateFlow()
    val playsCount: StateFlow<Int> = _playsCount.asStateFlow()
    val elapsedTime: StateFlow<Int> = _elapsedTime.asStateFlow()

    // Score info
    val highScore: StateFlow<Score?> = _highScore.asStateFlow()

    // User info
    val group: StateFlow<String> = storage.groupState
    val username: StateFlow<String> = storage.usernameState
    val difficulty: StateFlow<Difficulty> = storage.difficultyState

    fun updateConfig(
        username: String,
        group: String,
        difficulty: Difficulty,
    ) {
        val fetchScores = group != storage.group || difficulty != storage.difficulty

        if (difficulty != game.difficulty) {
            newGame(difficulty)
        }

        storage.username = username
        storage.difficulty = difficulty
        storage.group = group

        if (fetchScores) {
            fetchHighScore()
        }
    }

    fun newGame(difficulty: Difficulty = game.difficulty) {
        game = GameBoard(difficulty)

        // Reset
        _playsCount.value = 0
        _elapsedTime.value = 0
        _gameState.value = game.gameState
        _boardState.value = game.matrix
    }

    fun open(position: Pair<Int, Int>) {
        play { open(position) }
    }

    fun toggleMark(position: Pair<Int, Int>) {
        play { toggleMark(position) }
    }

    private fun play(action: GameBoard.() -> Unit) {
        if (game.gameState != GameState.ALIVE) return

        if (!isRunning) {
            isRunning = true
            elapsedTimeClock()
        }

        game.action()

        _playsCount.inc()
        _gameState.value = game.gameState
        _boardState.value = game.matrix

        when (game.gameState) {
            GameState.DEAD -> {
                isRunning = false
            }

            GameState.VICTORY -> {
                isRunning = false
                submitResult()
            }

            GameState.ALIVE -> {
                // Do Nothing
            }
        }
    }

    private fun elapsedTimeClock() {
        coroutineScope.launch {
            do {
                _elapsedTime.inc()
                delay(1_000)
            } while (isRunning)
        }
    }

    fun fetchHighScore() {
        coroutineScope.launch {
            _highScore.value = null
            _highScore.value = api.fetchHighScore(storage.difficulty, storage.group)
        }
    }

    private fun submitResult() {
        coroutineScope.launch {
            _highScore.value = api.submitScore(
                difficulty = storage.difficulty,
                group = storage.group,
                username = storage.username,
                score = score,
            ) ?: _highScore.value
        }
    }
}
