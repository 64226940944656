package dev.valvassori.theme

import dev.valvassori.ext.horizontalPadding
import dev.valvassori.ext.verticalPadding
import org.jetbrains.compose.web.css.*

object MineSweeperCSS : StyleSheet() {
    const val lowerContainer = "lowerContainer"
    const val higherContainer = "higherContainer"

    init {
        "html, body" style {
            property("overscroll-behavior-x", "none")
            property("overscroll-behavior-y", "none")
        }

        "body" style {
            lineHeight(0.px)
        }

        "table" style {
            property("border-collapse", "collapse")
            property("border-spacing", "0")
        }

        "td" style {
            padding(0.px)
        }

        "div" + id("root") style {
            height(96.vh)
            width(98.vw)
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
        }
    }

    val gameBar by style {
        height(56.px)
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.SpaceAround)

        "button" style {
            height(36.px)
            width(36.px)
            minHeight(36.px)
            minWidth(36.px)
            fontSize(28.px)
            padding(0.px)
        }
    }

    val gameBoard by style {
        marginTop(8.px)
        padding(2.px)
    }

    val sevenSegmentsContainer by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)
        height(32.px)
        minWidth(56.px)
        verticalPadding(2.px)
        horizontalPadding(4.px)
        backgroundColor(Color.black)
        property("inline-size", "min-content")

        "span" style {
            color(rgb(255, 44, 15))
            fontSize(28.px)
            fontFamily("SevenSegments")
            fontWeight("semi-bold")
        }
    }

    val gameButton by style {
        height(28.px)
        width(28.px)
        minHeight(28.px)
        minWidth(28.px)
        padding(0.px, 6.px, 0.px, 6.px)
        color(Color.black)

        self + className("active") style {
            fontWeight("bold")
            property(
                "box-shadow",
                "inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080;",
            )

            self + className("bt-1") style {
                color(Color.blue)
            }

            self + className("bt-2") style {
                color(Color.darkgreen)
            }

            self + className("bt-3") style {
                color(Color.red)
            }

            self + className("bt-4") style {
                color(Color.darkblue)
            }

            self + className("bt-5") style {
                color(Color.darkred)
            }

            self + className("bt-6") style {
                color(Color.cyan)
            }

            self + className("bt-7") style {
                color(Color.black)
            }

            self + className("bt-8") style {
                color(Color.gray)
            }

            self + className("bt-💣") style {
                backgroundColor(Color("#c52f2f"))
            }
        }
    }

    val modal by style {
        width(100.vw)
        height(100.vh)

        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        justifyContent(JustifyContent.Center)

        backgroundColor(rgba(0, 0, 0, .30))
        position(Position.Absolute)
        property("z-index", 999)
    }

    val menuWindow by style {
        child(self, className(NineEightCSS.window)) style {
            minWidth(200.px)
        }
    }

    val menuContent by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
    }

    val buttonGroup by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        alignSelf(AlignSelf.End)
    }
}
