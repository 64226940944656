package dev.valvassori.minesweeper.externals

import kotlin.js.Promise
import kotlin.js.json

/**
 * https://github.com/axios/axios/blob/v1.x/index.d.ts
 */

@JsModule("axios")
@JsNonModule
external val axios: Axios

external interface Axios {
    fun create(config: dynamic): Axios

    fun <T> get(path: String, config: dynamic): Promise<AxiosResponse<T>>
}

fun AxiosRequestConfig(params: dynamic) = json(
    "params" to params,
)

external interface AxiosResponse<T> {
    val data: T
    val status: Int
}
