package dev.valvassori.screen

import androidx.compose.runtime.Composable
import dev.valvassori.components.GameBar
import dev.valvassori.components.GameBoard
import dev.valvassori.components.GameStatus
import dev.valvassori.minesweeper.MinesweeperSharedViewModel
import dev.valvassori.nineeightcss.*
import dev.valvassori.theme.MineSweeperCSS

@Composable
fun GameWindow(viewModel: MinesweeperSharedViewModel) {
    Window {
        TitleBar(
            title = "Compose Mine Sweeper",
            buttons = {
                MinimizeButton()
                CloseButton()
            }
        )
        Container(contentPadding = true, attrs = { classes(MineSweeperCSS.higherContainer) }) {
            GameBar(viewModel)
            GameBoard(viewModel)
        }
        GameStatus(viewModel)
    }
}
