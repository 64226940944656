package dev.valvassori.nineeightcss

import androidx.compose.runtime.Composable
import dev.valvassori.theme.MineSweeperCSS
import dev.valvassori.theme.NineEightCSS
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLDivElement

@Composable
fun Window(
    attrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit
) {
    Div({
        attrs?.invoke(this)
        classes(NineEightCSS.window)
    }) {
        content()
    }
}

@Composable
fun ModalWindow(
    modalAttrs: AttrBuilderContext<HTMLDivElement>? = null,
    windowAttrs: AttrBuilderContext<HTMLDivElement>? = null,
    content: @Composable () -> Unit,
) {
    Div(attrs = {
        modalAttrs?.invoke(this)
        classes(MineSweeperCSS.modal)
    }) {
        Window(windowAttrs, content)
    }
}
