package dev.valvassori.nineeightcss

import androidx.compose.runtime.Composable
import dev.valvassori.theme.NineEightCSS
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.forId
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Input as HTMLInput
import org.jetbrains.compose.web.dom.Option as HTMLOption
import org.jetbrains.compose.web.dom.Select as HTMLSelect

@Composable
fun Input(
    id: String,
    label: String,
    inputType: InputType<String>,
    value: String,
    maxLength: Int? = null,
    onChange: (newValue: String) -> Unit,
) {
    Div(attrs = {
        classes(NineEightCSS.fieldRowStack)

        if (maxLength != null && maxLength > 0) {
            attr("maxlength", maxLength.toString())
        }
    }) {
        Label(attrs = { forId(id) }) { Text(label) }
        HTMLInput(inputType) {
            id(id)
            attr("value", value)
            onChange { onChange(it.value) }
        }
    }
}

@Composable
fun Select(
    id: String,
    label: String,
    options: Map<String, String>,
    selectedOption: String?,
    onSelect: (newValue: String?) -> Unit,
) {
    Div(attrs = { classes(NineEightCSS.fieldRowStack) }) {
        Label(attrs = { forId(id) }) { Text(label) }
        HTMLSelect(attrs = {
            id(id)
            onChange { onSelect(it.value) }
        }) {
            options.forEach { (key, value) ->
                HTMLOption(
                    value = key,
                    attrs = { if (key == selectedOption) selected() },
                    content = { Text(value) }
                )
            }
        }
    }
}
