package dev.valvassori.viewmodel

import kotlinx.browser.localStorage
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import org.w3c.dom.get
import org.w3c.dom.set

private const val HAS_SHOWN_MENU_KEY = "has_shown_menu"
class MineSweeperInternalStateViewModel {
    private val _isMenuOpen = MutableStateFlow(!localStorage[HAS_SHOWN_MENU_KEY].toBoolean())
    val isMenuOpen: StateFlow<Boolean> = _isMenuOpen.asStateFlow()

    fun setMenuOpen(value: Boolean = true) {
        _isMenuOpen.value = value

        if (!value) localStorage[HAS_SHOWN_MENU_KEY] = "true"
    }
}
