package dev.valvassori.components

import androidx.compose.runtime.Composable
import dev.valvassori.theme.MineSweeperCSS
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun SevenSegmentsText(text: String) {
    Div({ classes(MineSweeperCSS.sevenSegmentsContainer) }) {
        Span { Text(text) }
    }
}
