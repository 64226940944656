package dev.valvassori.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import dev.valvassori.minesweeper.MinesweeperSharedViewModel
import dev.valvassori.nineeightcss.Container
import dev.valvassori.theme.MineSweeperCSS
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Text

@Composable
fun GameBar(viewModel: MinesweeperSharedViewModel) {
    val playsCount by viewModel.playsCount.collectAsState()
    val gameState by viewModel.gameState.collectAsState()
    val elapsedTime by viewModel.elapsedTime.collectAsState()

    Container(windowBody = false, attrs = { classes(MineSweeperCSS.lowerContainer, MineSweeperCSS.gameBar) }) {
        SevenSegmentsText(text = elapsedTime.toString().padStart(4, '0'))
        Button({ onClick { viewModel.newGame() } }) { Text(gameState.emoji) }
        SevenSegmentsText(text = playsCount.toString().padStart(4, '0'))
    }
}
