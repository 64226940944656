package dev.valvassori.minesweeper.datasource

import dev.valvassori.minesweeper.model.Difficulty
import dev.valvassori.minesweeper.externals.AxiosRequestConfig
import dev.valvassori.minesweeper.externals.axios
import dev.valvassori.minesweeper.model.Env
import dev.valvassori.minesweeper.model.Score
import kotlinx.coroutines.await
import kotlin.js.json

actual fun MineSweeperAPI(env: Env): MineSweeperAPI = JSMineSweeperAPI(env)

private class JSMineSweeperAPI(env: Env) : MineSweeperAPI {
    private val instance by lazy {
        axios.create(
            json(
                "baseURL" to when (env) {
                    Env.Local -> "http://127.0.0.1:5001/kotlin-functions-babb1/us-central1/"
                    else -> "https://us-central1-kotlin-functions-babb1.cloudfunctions.net/"
                },
            )
        )
    }

    override suspend fun fetchHighScore(
        difficulty: Difficulty,
        group: String,
    ): Score? = try {
        instance.get<Score>(
            "/getHighScore",
            AxiosRequestConfig(
                params = json(
                    "difficulty" to difficulty.name,
                    "group" to group,
                )
            )
        ).await().data
    } catch (e: Throwable) {
        null
    }

    override suspend fun submitScore(
        difficulty: Difficulty,
        group: String,
        username: String,
        score: Int,
    ): Score? = try {
        instance.get<Score>(
            "/saveHighScoreQuery",
            AxiosRequestConfig(
                params = json(
                    "difficulty" to difficulty.name,
                    "group" to group,
                    "username" to username,
                    "score" to score,
                )
            )
        ).await().data
    } catch (e: Throwable) {
        null
    }

}
