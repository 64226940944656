package dev.valvassori

import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import dev.valvassori.minesweeper.MinesweeperSharedViewModel
import dev.valvassori.screen.GameWindow
import dev.valvassori.screen.Menu
import dev.valvassori.viewmodel.MineSweeperInternalStateViewModel

@Composable
fun Minesweeper(
    viewModel: MinesweeperSharedViewModel,
    internalStateViewModel: MineSweeperInternalStateViewModel
) {
    val isMenuOpen by internalStateViewModel.isMenuOpen.collectAsState()

    if (isMenuOpen) {
        Menu(
            viewModel = viewModel,
            onCloseMenu = { internalStateViewModel.setMenuOpen(false) },
        )
    }

    GameWindow(viewModel)
}
