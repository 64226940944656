package dev.valvassori.minesweeper.datasource

import com.russhwolf.settings.Settings
import com.russhwolf.settings.get
import com.russhwolf.settings.set
import dev.valvassori.minesweeper.model.Difficulty
import dev.valvassori.minesweeper.helpers.nextAnonUser
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow

fun MineSweeperStorage(dependencies: MineSweeperStorageDependencies): MineSweeperStorage =
    CommonMineSweeperStorage(createMineSweeperStorage(dependencies))

interface MineSweeperStorage {
    var group: String
    val groupState: StateFlow<String>

    var username: String
    val usernameState: StateFlow<String>

    var difficulty: Difficulty
    val difficultyState: StateFlow<Difficulty>
}

expect class MineSweeperStorageDependencies
internal expect fun createMineSweeperStorage(dependencies: MineSweeperStorageDependencies): Settings

private const val GROUP_KEY = "group"
private const val USERNAME_KEY = "username"
private const val DIFFICULTY_KEY = "difficulty"

private class CommonMineSweeperStorage(
    private val storage: Settings,
) : MineSweeperStorage {
    private val _group = MutableStateFlow(group)
    private val _username = MutableStateFlow(username)
    private val _difficulty = MutableStateFlow(difficulty)

    override val groupState by lazy { _group.asStateFlow() }
    override val usernameState by lazy { _username.asStateFlow() }
    override val difficultyState by lazy { _difficulty.asStateFlow() }

    override var group: String
        get() = storage[GROUP_KEY] ?: "global"
        set(value) {
            val normalizedValue = value.trim().takeIf { it.isNotBlank() } ?: "global"
            storage[GROUP_KEY] = normalizedValue
            _group.value = normalizedValue
        }
    override var username: String
        get() = storage[USERNAME_KEY] ?: nextAnonUser().also { storage[USERNAME_KEY] = it }
        set(value) {
            val normalizedValue = value.trim().takeIf { it.isNotBlank() } ?: nextAnonUser()
            storage[USERNAME_KEY] = normalizedValue
            _username.value = normalizedValue
        }
    override var difficulty: Difficulty
        get() = Difficulty.parse(storage[DIFFICULTY_KEY])
        set(value) {
            storage[DIFFICULTY_KEY] = value.name
            _difficulty.value = value
        }
}
