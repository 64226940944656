package dev.valvassori.minesweeper.domain

import dev.valvassori.minesweeper.ext.flattenAndFilter
import dev.valvassori.minesweeper.ext.isDigit
import dev.valvassori.minesweeper.model.Difficulty
import dev.valvassori.minesweeper.model.GameState
import dev.valvassori.minesweeper.model.NodeData

internal data class GameBoard(val difficulty: Difficulty) {
    private val rows = difficulty.size.first
    private val columns = difficulty.size.second

    var gameState: GameState = GameState.ALIVE
        private set(value) {
            field = value
            isAlive = gameState != GameState.DEAD
            hasWon = gameState == GameState.VICTORY
        }

    private var isAlive: Boolean = true
    private var hasWon: Boolean = false

    val matrix = Array(rows) { Array(columns) { NodeData.createEmpty() } }

    init {
        // Sorting bombs
        var mineCount = 0
        val rowRange = (0 until rows)
        val columRange = (0 until columns)

        while (mineCount < difficulty.mines) {
            val nextRow = rowRange.random()
            val nextColumn = columRange.random()

            if (matrix[nextRow][nextColumn].isMine)
                continue

            matrix[nextRow][nextColumn] = NodeData.createMine()

            aroundIdx.forEach { (row, column) ->
                if (nextRow + row >= rows || nextRow + row < 0)
                    return@forEach

                if (nextColumn + column >= columns || nextColumn + column < 0)
                    return@forEach

                matrix[nextRow + row][nextColumn + column] =
                    matrix[nextRow + row][nextColumn + column].inc()
            }

            mineCount += 1
        }
    }

    fun open(position: Pair<Int, Int>) {
        val node = matrix[position.first][position.second]
        if (!isAlive || node.isOpen) return

        matrix[position.first][position.second] = node.open()

        if (node.isMine) gameState = GameState.DEAD
        if (isAlive && node.isEmpty) expandOnEmpty(position)

        val allNumbersOpen = matrix
            .flattenAndFilter { it.value.isDigit() }
            .none { !it.isOpen }

        if (allNumbersOpen) gameState = GameState.VICTORY
    }

    fun toggleMark(position: Pair<Int, Int>) {
        val node = matrix[position.first][position.second]
        if (!isAlive || node.isOpen) return

        matrix[position.first][position.second] = node.toggleMark()
    }

    private fun expandOnEmpty(position: Pair<Int, Int>) {
        val (currentRow, currentColumn) = position
        aroundIdx.forEach { (row, column) ->
            val nextRow = currentRow + row
            val nextColumn = currentColumn + column

            if (nextRow >= rows || nextRow < 0) return@forEach
            if (nextColumn >= columns || nextColumn < 0) return@forEach

            open(nextRow to nextColumn)
        }
    }
}

private val aroundIdx = arrayOf(
    -1 to -1, -1 to 0, -1 to 1,
    0 to -1, 0 to 1,
    1 to -1, 1 to 0, 1 to 1,
)
