import androidx.compose.runtime.LaunchedEffect
import dev.valvassori.Minesweeper
import dev.valvassori.minesweeper.MinesweeperSharedViewModel
import dev.valvassori.minesweeper.datasource.MineSweeperStorageDependencies
import dev.valvassori.minesweeper.model.Env
import dev.valvassori.theme.MineSweeperCSS
import dev.valvassori.theme.NineEightCSS
import dev.valvassori.viewmodel.MineSweeperInternalStateViewModel
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.events.KeyboardEvent

fun main() {
    val (viewModel, internalStateViewModel) = setupViewModels()
    setupDocumentEvents(internalStateViewModel)

    renderComposable(rootElementId = "root") {
        Style(NineEightCSS)
        Style(MineSweeperCSS)

        Minesweeper(
            viewModel = viewModel,
            internalStateViewModel = internalStateViewModel,
        )

        LaunchedEffect(Unit) {
            viewModel.fetchHighScore()
        }
    }
}

private fun setupViewModels(): Pair<MinesweeperSharedViewModel, MineSweeperInternalStateViewModel> {
    val env = if (window.location.href.contains("localhost")) Env.Local else Env.Prod
    val storageDeps = MineSweeperStorageDependencies.shared

    val viewModel = MinesweeperSharedViewModel(env, storageDeps)
    val internalStateViewModel = MineSweeperInternalStateViewModel()

    return viewModel to internalStateViewModel
}

private fun setupDocumentEvents(internalStateViewModel: MineSweeperInternalStateViewModel) {
    // Disable right click menu
    document.addEventListener("contextmenu", callback = { it.preventDefault() })
    document.addEventListener(
        type = "keydown",
        callback = {
            // F1 press
            if (it is KeyboardEvent && it.keyCode == 112) {
                internalStateViewModel.setMenuOpen(true)
            }
        },
    )
}

