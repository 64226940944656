package dev.valvassori.components

import androidx.compose.runtime.Composable
import dev.valvassori.ext.isRightClick
import dev.valvassori.minesweeper.model.GameState
import dev.valvassori.minesweeper.model.NodeData
import dev.valvassori.theme.MineSweeperCSS
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text

@Composable
fun FieldItem(
    position: Pair<Int, Int>,
    data: NodeData,
    gameState: GameState,
    open: (Pair<Int, Int>) -> Unit,
    toggleMark: (Pair<Int, Int>) -> Unit,
) {
    val isVisible = when (gameState) {
        GameState.DEAD -> data.isMine || data.isOpen
        else -> data.isOpen
    }

    Td {
        Button(
            attrs = {
                classes(
                    setOfNotNull(
                        MineSweeperCSS.gameButton,
                        "active".takeIf { data.isOpen },
                        "bt-${data.value}".takeIf { isVisible },
                    )
                )
                onMouseDown {
                    if (it.isRightClick) {
                        toggleMark(position)
                        it.preventDefault()
                        it.stopPropagation()
                    }
                }
                onMouseUp {
                    if (it.isRightClick) return@onMouseUp
                    open(position)
                }
            }
        ) {
            if (isVisible) Text(data.value)
            else Text(data.mark.symbol)
        }
    }
}
