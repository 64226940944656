package dev.valvassori.screen

import androidx.compose.runtime.*
import dev.valvassori.minesweeper.MinesweeperSharedViewModel
import dev.valvassori.minesweeper.model.Difficulty
import dev.valvassori.nineeightcss.*
import dev.valvassori.theme.MineSweeperCSS
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun Menu(
    viewModel: MinesweeperSharedViewModel,
    onCloseMenu: () -> Unit,
) {
    var username by remember { mutableStateOf(viewModel.username.value) }
    var difficulty by remember { mutableStateOf(viewModel.difficulty.value) }
    var group by remember { mutableStateOf(viewModel.group.value) }

    ModalWindow(modalAttrs = { classes(MineSweeperCSS.menuWindow) }) {
        TitleBar(
            title = "Menu",
            buttons = { CloseButton { onClick { onCloseMenu() } } },
        )
        Container(
            contentPadding = true,
            attrs = { classes(MineSweeperCSS.menuContent) }
        ) {
            Input(
                id = "username",
                label = "Username",
                inputType = InputType.Text,
                value = username,
                maxLength = 14,
                onChange = { username = it },
            )

            Input(
                id = "group",
                label = "Group",
                inputType = InputType.Text,
                value = group,
                maxLength = 20,
                onChange = { group = it },
            )

            Select(
                id = "difficulty",
                label = "Difficulty",
                options = Difficulty.values().associate { it.name to it.prettyName },
                selectedOption = difficulty.name,
                onSelect = { difficulty = Difficulty.parse(it) }
            )

            Div(attrs = { classes(MineSweeperCSS.buttonGroup) }) {
                Button(attrs = {
                    style { marginRight(6.px) }
                    onClick {
                        viewModel.updateConfig("", "", Difficulty.BEGINNER)
                        onCloseMenu()
                    }
                }) {
                    Text("Reset")
                }
                Button(attrs = {
                    onClick {
                        viewModel.updateConfig(username, group, difficulty)
                        onCloseMenu()
                    }
                }) {
                    Text("Save")
                }
            }
        }
    }
}
