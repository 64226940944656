package dev.valvassori.minesweeper.datasource

import com.russhwolf.settings.Settings
import com.russhwolf.settings.StorageSettings

actual class MineSweeperStorageDependencies {
    companion object {
        val shared by lazy { MineSweeperStorageDependencies() }
    }
}
internal actual fun createMineSweeperStorage(
    dependencies: MineSweeperStorageDependencies,
): Settings = StorageSettings()
